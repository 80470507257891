import React from 'react'
import {Link} from 'gatsby'
import styles from './header.module.css'

import HeaderLogoSydney from '../assets/sydneyAssets/logo_HeaderLogo.svg'
import HeaderLogoMelbourne from '../assets/melbourneAssets/logo_HeaderLogo.svg'
import HeaderLogoAdelaide from '../assets/adelaideAssets/logo_HeaderLogo.svg'

const LOADED_META_DATA = 'loadedmetadata';
const VIDEO_ENDED = 'ended';
const CAN_PLAY_THROUGH = 'canplaythrough';

let siteBaseUrl = 'https://d2f6enpo6mvwml.cloudfront.net';
if (process.env.BRANCH === "master") {
    siteBaseUrl = 'https://www.com.au';
}

class HeaderDesktopView extends React.Component {
    constructor(props) {
        super(props);


        this.myRef = React.createRef();
       this.videoElements = [];
       this.videos = [];
       this.videosContain = [];
       this.videosTabs = [];
       this.zIndexNumber = 1;

       this.videoTitles = [];
       this.videoLinks = [];
       this.videoClients = [];
       this.videoPoster = [];

       
       this.props.videoItems.forEach((link, num) => {
           let url = link.urlLink ? `${siteBaseUrl}${link.urlLink}` : `${siteBaseUrl}${link.upload.asset.url}`;
           this.videoLinks.push(url);
           this.videoTitles.push(link.title);
           this.videoClients.push(link.client);
           this.videoPoster.push(link.posterImage);
           
        })
        
        this.currentItem = -1;
        this.counter = 0;
        
    }
    
    componentDidMount() {
        this.videos.forEach((item, num) => {
            if (num == 1) {
                item.onLoad && item.onLoad();
                this.playNext();
                this.videosContain[0].style.opacity = 1;
                this.videosTabs[0].classList.add(styles.selected);
            }
            item.load();
            
        })
    }

    selectedVideoItem(event, number) {
        this.playNext(number);
    }

    playNext(selectedNumber) {
        let prevItem = this.currentItem;
        this.zIndexNumber++;

        if (typeof selectedNumber === 'number') {
            this.currentItem = selectedNumber;
        } else {
            this.currentItem++;
        }
        if (this.currentItem >= this.videos.length) {
            this.currentItem = 0;
        }
        let item = this.videos[this.currentItem];
        if (item.readyState == 4) { // 4 - HAVE_ENOUGH_DATA
            item.currentTime = '0';
            item.play();
        } else {
            this.currentItem = 0;
            this.videos[0].currentTime = '0';
            this.videos[0].play();
        }
        if (prevItem != undefined && this.videos[prevItem] != undefined && prevItem != this.currentItem) {
            this.videosContain[this.currentItem].style.opacity = 1;
            this.videosContain[this.currentItem].style.zIndexNumber = this.zIndexNumber;
            this.videosContain[prevItem].style.opacity = 0;
            this.videos[prevItem].pause();
            this.videosTabs[prevItem].classList.remove(styles.selected);
            this.videosTabs[this.currentItem].classList.add(styles.selected);
        }
    }

    handleEvent(event) {

        switch (event.type) {

            case VIDEO_ENDED:
                this.playNext();
                break;

            case CAN_PLAY_THROUGH:
                // return true;
                if (this.videos[this.currentItem].readyState != 4) {
                    return true;
                }
                var i = this.currentItem;
                do {
                    i++;
                } while (i < this.videos.length && this.videos[i].readyState == 4);
                if (i < this.videos.length) {
                    this.videos[i].load();
                }
                break;
        }
    }


    render() {

        const Wrapper = ({children, condition, wrapper}) =>
            condition ? wrapper(children) : children
        let headerLogoSrc = ''
        if (this.props.site === 'Sydney') {
            headerLogoSrc = HeaderLogoSydney;
        } else if (this.props.site === 'Melbourne') {
            headerLogoSrc = HeaderLogoMelbourne;
        } else if (this.props.site === 'Adelaide') {
            headerLogoSrc = HeaderLogoAdelaide;
        }

        
       

        return (
                    <section>
                        <div ref={this.myRef} className={styles.videoContainer}>

                            {this.videoLinks.map((link, num) => (
                                <div key={"vid" + num}
                                    ref={ref => this.videosContain[num] = ref}
                                    className={styles.videoItem}
                                >
                                    <Wrapper
                                        condition={this.props.videoItems[num].relatedProject}
                                        wrapper={
                                            children => <Link className={styles.linkTag}
                                                to={`./work/${this.props.videoItems[num].relatedProject.slug.current}`}>
                                                {children}
                                            </Link>}
                                    >
                                        <span className={styles.videoTitle}>
                                            <strong>{this.videoClients[num]}</strong>
                                            <br />
                                            {this.videoTitles[num]}
                                        </span>
                                        <video ref={ref => this.videos[num] = ref} className={styles.video}
                                            onCanPlayThrough={e => this.handleEvent(e)}
                                            onEnded={e => this.handleEvent(e)}
                                            width="16" height="9"
                                            muted playsInline
                                            src={link} type="video/mp4">
                                        </video>
                                     </Wrapper>
                                 </div>
                            ))}


                            <div className={styles.videoSelectionList}>
                                {this.videoLinks.map((link, num) => (
                                    <div key={"vidSelect" + num} ref={ref => this.videosTabs[num] = ref}
                                        className={styles.videoSelectionItem} onClick={(e) => {
                                            this.selectedVideoItem(e, num)
                                        }}></div>
                                ))}
                            </div>

                            <img className={styles.headerLogo} src={headerLogoSrc} />


                        </div>
                    </section>
        );
    }
}

export default HeaderDesktopView