import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import styles from './header-mobile.module.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import HeaderLogoSydney from '../assets/sydneyAssets/logo_HeaderLogo.svg'
import HeaderLogoMelbourne from '../assets/melbourneAssets/logo_HeaderLogo.svg'
import HeaderLogoAdelaide from '../assets/adelaideAssets/logo_HeaderLogo.svg'

let siteBaseUrl = 'https://d2f6enpo6mvwml.cloudfront.net';
if (process.env.BRANCH === "master") {
    siteBaseUrl = 'https://www.com.au';
}


export default function HeaderMobileView(props) {
    const { projects, site } = props;


    const slideImages = [];
    const imageTitle = [];
    const imageClient = [];
    const imageLinks = [];

    
    
    projects.forEach(project => {
        let projectUrl = project.urlLink ? `${siteBaseUrl}${project.urlLink}` : `${siteBaseUrl}${project.upload.asset.url}`;
        
        imageLinks.push(projectUrl);
        imageTitle.push(project.title);
        imageClient.push(project.client);
        slideImages.push(project.posterImage.asset.fluid);
        
    });
    

    let headerLogoSrc = ''
    if (site === 'Sydney') {
        headerLogoSrc = HeaderLogoSydney;
    } else if (site === 'Melbourne') {
        headerLogoSrc = HeaderLogoMelbourne;
    } else if (site === 'Adelaide') {
        headerLogoSrc = HeaderLogoAdelaide;
    }
    
    

    return (
        
        <div>
            <Carousel
                autoPlay
                interval={3000}
                showThumbs={false}
                infiniteLoop
                showArrows={false}
                showStatus={false}
                swipeable={false}
                renderIndicator={(onClickHandler, isSelected, index) => {
                    return (
                        <div
                            key={index}
                            className={`${isSelected ? `${styles.slideshowTab} + ${styles.selected}` : styles.slideshowTab}`}
                            onClick={onClickHandler}
                        ></div>
                    )
                }}
            >
                {slideImages.map((backgroundImage, index) => (
                    
                    <>
                    <Link to={`./work/${projects[index].relatedProject.slug.current}`}>
                        
                        <Img 
                            className={styles.slide} 
                            key={index} 
                            fluid={backgroundImage} 
                        />
                    

                        <span className={styles.videoTitle}>
                            <strong>{projects[index].title}</strong>
                            <br />
                            {projects[index].client}
                        </span>
                    </Link>
                    </>
                ))}
            </Carousel>


            <img className={styles.headerLogo} src={headerLogoSrc} />
        </div>
    );
}

