import React from 'react'
import { BrowserView, MobileView } from 'react-device-detect';
import HeaderMobileView from './header-mobile-view';
import HeaderDesktopView from './header-desktop-view';


let siteBaseUrl = 'https://www.tbwa.com.au';
if (process.env.BRANCH==="master"){
  siteBaseUrl = 'https://wwww.tbwa.com.au';
}

class Header extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {


        return (
            <>
                <BrowserView>
                   <HeaderDesktopView videoItems={this.props.videoItems} site={this.props.site} />
                </BrowserView>
                <MobileView>
                    <HeaderMobileView projects={this.props.videoItems} site={this.props.site} />
                </MobileView>
            </>
        );
    }
}

export default Header